import React, { useEffect, useState, useRef } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { Filter } from "../../../shared/types";

interface PriceRangeProps {
  updateFilter: (newFilterValues: Partial<Filter>) => void;
}

export const priceRanges = [
  { label: "Dưới 500.000 đ", minPrice: null, maxPrice: 500000 },
  { label: "500.000 đ - 1 triệu", minPrice: 500000, maxPrice: 1000000 },
  { label: "1 triệu - 2 triệu", minPrice: 1000000, maxPrice: 2000000 },
  { label: "2 triệu - 3 triệu", minPrice: 2000000, maxPrice: 3000000 },
  { label: "Trên 3 triệu", minPrice: 3000000, maxPrice: null },
];

const FilterPrice: React.FC<PriceRangeProps> = ({ updateFilter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRange, setSelectedRange] = useState<number | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null); // Reference for outside click

  // Initialize the selected price range based on query params
  useEffect(() => {
    const minPrice = searchParams.get("minPrice")
      ? Number(searchParams.get("minPrice"))
      : null;
    const maxPrice = searchParams.get("maxPrice")
      ? Number(searchParams.get("maxPrice"))
      : null;

    const foundIndex = priceRanges.findIndex(
      (range) => range.minPrice === minPrice && range.maxPrice === maxPrice
    );

    setSelectedRange(foundIndex !== -1 ? foundIndex : null);
  }, [searchParams]);

  const handlePriceChange = (
    minPrice: number | null,
    maxPrice: number | null,
    index: number
  ) => {
    if (selectedRange === index) {
      // Deselect the range if the same one is clicked
      setSelectedRange(null);
      updateFilter({ minPrice: null, maxPrice: null });
    } else {
      // Select a new price range
      setSelectedRange(index);
      updateFilter({ minPrice, maxPrice, pageIndex: 1 });

      // Update the query params in the URL
      // const params = new URLSearchParams(searchParams);
      // if (minPrice !== null) params.set("minPrice", minPrice.toString());
      // if (maxPrice !== null) params.set("maxPrice", maxPrice.toString());
      // setSearchParams(params);
    }
  };

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Filter Header */}
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className="flex justify-between items-center px-4 py-2 border rounded-md shadow-sm text-gray-700 bg-white focus:outline-none w-[250px]"
      >
        <span>Khoảng giá</span>
        <MdKeyboardArrowDown
          size={20}
          className={`transition-transform duration-200 ${
            isOpen ? "-rotate-90" : "rotate-0"
          }`}
        />
      </button>

      {/* Dropdown Content */}
      {isOpen && (
        <div className="absolute z-10 mt-2 bg-white border rounded-md shadow-lg p-4 w-[250px]">
          <ul className="space-y-2">
            {priceRanges.map((range, index) => (
              <li key={index} className="flex items-center">
                <button
                  onClick={() =>
                    handlePriceChange(range.minPrice, range.maxPrice, index)
                  }
                  className="flex items-center gap-2 w-full text-left"
                >
                  <span
                    className={`w-5 h-5 flex items-center justify-center rounded-full border-2 ${
                      selectedRange === index
                        ? "bg-white border-primary"
                        : "border-gray-400"
                    }`}
                  >
                    {selectedRange === index && (
                      <span className="w-3 h-3 bg-primary rounded-full"></span>
                    )}
                  </span>
                  <span
                    className={`${
                      selectedRange === index && "text-primary font-medium"
                    }`}
                  >
                    {range.label}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FilterPrice;

// import React, { useEffect, useState, useRef } from "react";
// import {
//   MdKeyboardArrowDown,
//   MdOutlineCheckBox,
//   MdOutlineCheckBoxOutlineBlank,
// } from "react-icons/md";
// import { useSearchParams } from "react-router-dom";
// import { Filter } from "../../../shared/types";

// interface PriceRangeProps {
//   updateFilter: (newFilterValues: Partial<Filter>) => void;
// }

// export const priceRanges = [
//   { label: "Dưới 500.000 đ", minPrice: null, maxPrice: 500000 },
//   { label: "500.000 đ - 1 triệu", minPrice: 500000, maxPrice: 1000000 },
//   { label: "1 triệu - 2 triệu", minPrice: 1000000, maxPrice: 2000000 },
//   { label: "2 triệu - 3 triệu", minPrice: 2000000, maxPrice: 3000000 },
//   { label: "Trên 3 triệu", minPrice: 3000000, maxPrice: null },
// ];

// const FilterPrice: React.FC<PriceRangeProps> = ({ updateFilter }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [searchParams, setSearchParams] = useSearchParams();
//   const [selectedRange, setSelectedRange] = useState<number | null>(null);
//   const dropdownRef = useRef<HTMLDivElement>(null); // Reference for outside click

//   // Initialize the selected price range based on query params
//   useEffect(() => {
//     const minPrice = searchParams.get("minPrice")
//       ? Number(searchParams.get("minPrice"))
//       : null;
//     const maxPrice = searchParams.get("maxPrice")
//       ? Number(searchParams.get("maxPrice"))
//       : null;

//     const foundIndex = priceRanges.findIndex(
//       (range) => range.minPrice === minPrice && range.maxPrice === maxPrice
//     );

//     setSelectedRange(foundIndex !== -1 ? foundIndex : null);
//   }, [searchParams]);

//   const handlePriceChange = (
//     minPrice: number | null,
//     maxPrice: number | null,
//     index: number
//   ) => {
//     if (selectedRange === index) {
//       // Deselect the range if the same one is clicked
//       setSelectedRange(null);
//       updateFilter({ minPrice: null, maxPrice: null });
//       // setSearchParams({});
//     } else {
//       // Select a new price range
//       setSelectedRange(index);
//       updateFilter({ minPrice, maxPrice, pageIndex: 1 });

//       // Update the query params in the URL
//       const params = new URLSearchParams(searchParams);
//       if (minPrice !== null) params.set("minPrice", minPrice.toString());
//       if (maxPrice !== null) params.set("maxPrice", maxPrice.toString());
//       setSearchParams(params);
//     }
//   };

//   // Close dropdown on outside click
//   useEffect(() => {
//     const handleClickOutside = (event: MouseEvent) => {
//       if (
//         dropdownRef.current &&
//         !dropdownRef.current.contains(event.target as Node)
//       ) {
//         setIsOpen(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   return (
//     <div className="relative" ref={dropdownRef}>
//       {/* Filter Header */}
//       <button
//         onClick={() => setIsOpen((prev) => !prev)}
//         className="flex justify-between items-center px-4 py-2 border rounded-md shadow-sm text-gray-700 bg-white focus:outline-none w-[250px]"
//       >
//         <span>Khoảng giá</span>
//         <MdKeyboardArrowDown
//           size={20}
//           className={`transition-transform duration-200 ${
//             isOpen ? "-rotate-90" : "rotate-0"
//           }`}
//         />
//       </button>

//       {/* Dropdown Content */}
//       {isOpen && (
//         <div className="absolute z-10 mt-2  bg-white border rounded-md shadow-lg p-4 w-[250px]">
//           <ul className="space-y-2">
//             {priceRanges.map((range, index) => (
//               <li key={index} className="flex items-center">
//                 <button
//                   onClick={() =>
//                     handlePriceChange(range.minPrice, range.maxPrice, index)
//                   }
//                   className="flex items-center gap-2 w-full text-left"
//                 >
//                   {selectedRange === index ? (
//                     <MdOutlineCheckBox size={20} className="text-primary" />
//                   ) : (
//                     <MdOutlineCheckBoxOutlineBlank size={20} />
//                   )}
//                   <span
//                     className={`${
//                       selectedRange === index && "text-primary font-medium"
//                     }`}
//                   >
//                     {range.label}
//                   </span>
//                 </button>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//     </div>
//   );
// };

// export default FilterPrice;

// import React, { useEffect, useState } from "react";
// import {
//   MdKeyboardArrowDown,
//   MdOutlineCheckBox,
//   MdOutlineCheckBoxOutlineBlank,
// } from "react-icons/md";
// import { useSearchParams } from "react-router-dom";
// import { Filter } from "../../../shared/types";

// interface PriceRangeProps {
//   updateFilter: (newFilterValues: Partial<Filter>) => void;
// }

// export const priceRanges = [
//   { label: "Dưới 500.000 đ", minPrice: 0, maxPrice: 500000 },
//   { label: "500.000 đ - 1 triệu", minPrice: 500000, maxPrice: 1000000 },
//   { label: "1 triệu - 2 triệu", minPrice: 1000000, maxPrice: 2000000 },
//   { label: "2 triệu - 3 triệu", minPrice: 2000000, maxPrice: 3000000 },
//   { label: "Trên 3 triệu", minPrice: 3000000, maxPrice: null },
// ];

// const FilterPrice: React.FC<PriceRangeProps> = ({ updateFilter }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [searchParams, setSearchParams] = useSearchParams();
//   const [selectedRange, setSelectedRange] = useState<number | null>(null);

//   // Initialize the selected price range based on query params
//   useEffect(() => {
//     const minPrice = searchParams.get("minPrice")
//       ? Number(searchParams.get("minPrice"))
//       : null;
//     const maxPrice = searchParams.get("maxPrice")
//       ? Number(searchParams.get("maxPrice"))
//       : null;

//     const foundIndex = priceRanges.findIndex(
//       (range) => range.minPrice === minPrice && range.maxPrice === maxPrice
//     );

//     setSelectedRange(foundIndex !== -1 ? foundIndex : null);
//   }, [searchParams]);

//   const handlePriceChange = (
//     minPrice: number | null,
//     maxPrice: number | null,
//     index: number
//   ) => {
//     if (selectedRange === index) {
//       // Deselect the range if the same one is clicked
//       setSelectedRange(null);
//       updateFilter({ minPrice: null, maxPrice: null });
//       setSearchParams({});
//     } else {
//       // Select a new price range
//       setSelectedRange(index);
//       updateFilter({ minPrice, maxPrice, pageIndex: 1 });

//       // Update the query params in the URL
//       const params = new URLSearchParams(searchParams);
//       if (minPrice !== null) params.set("minPrice", minPrice.toString());
//       if (maxPrice !== null) params.set("maxPrice", maxPrice.toString());
//       setSearchParams(params);
//     }
//   };

//   return (
//     <div className="relative">
//       {/* Filter Header */}
//       <button
//         onClick={() => setIsOpen((prev) => !prev)}
//         className="w-full flex justify-between items-center px-4 py-2 border rounded-md shadow-sm text-gray-700 bg-white focus:outline-none"
//       >
//         <span>Khoảng giá</span>
//         <MdKeyboardArrowDown
//           size={20}
//           className={`transition-transform duration-200 ${
//             isOpen ? "rotate-180" : "rotate-0"
//           }`}
//         />
//       </button>

//       {/* Dropdown Content */}
//       {isOpen && (
//         <div className="absolute z-10 mt-2 w-full bg-white border rounded-md shadow-lg p-4">
//           <ul className="space-y-2">
//             {priceRanges.map((range, index) => (
//               <li key={index} className="flex items-center">
//                 <button
//                   onClick={() =>
//                     handlePriceChange(range.minPrice, range.maxPrice, index)
//                   }
//                   className="flex items-center gap-2 w-full text-left"
//                 >
//                   {selectedRange === index ? (
//                     <MdOutlineCheckBox size={20} className="text-blue-600" />
//                   ) : (
//                     <MdOutlineCheckBoxOutlineBlank size={20} />
//                   )}
//                   <span>{range.label}</span>
//                 </button>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//     </div>
//   );
// };

// export default FilterPrice;

// import { useEffect, useState } from "react";
// import {
//   MdKeyboardArrowDown,
//   MdKeyboardArrowRight,
//   MdOutlineCheckBox,
//   MdOutlineCheckBoxOutlineBlank,
// } from "react-icons/md";
// import { useSearchParams } from "react-router-dom";
// import { Filter } from "../../../shared/types";

// interface PriceRangeProps {
//   updateFilter: (newFilterValues: Partial<Filter>) => void;
// }

// export const priceRanges = [
//   { label: "Dưới 500.000 đ", minPrice: 0, maxPrice: 500000 },
//   { label: "500.000 đ - 1 triệu", minPrice: 500000, maxPrice: 1000000 },
//   { label: "1 triệu - 2 triệu", minPrice: 1000000, maxPrice: 2000000 },
//   { label: "2 triệu - 3 triệu", minPrice: 2000000, maxPrice: 3000000 },
//   { label: "Trên 3 triệu", minPrice: 3000000, maxPrice: null },
// ];

// const FilterPrice: React.FC<PriceRangeProps> = ({ updateFilter }) => {
//   const [isOpen, setIsOpen] = useState(true);
//   const [searchParams] = useSearchParams();
//   const [selectedRange, setSelectedRange] = useState<number | null>(null);

//   useEffect(() => {
//     const minPrice = searchParams.get("minPrice")
//       ? Number(searchParams.get("minPrice"))
//       : 0;
//     const maxPrice = searchParams.get("maxPrice")
//       ? Number(searchParams.get("maxPrice"))
//       : null;

//     const foundIndex = priceRanges.findIndex(
//       (range) => range.minPrice === minPrice && range.maxPrice === maxPrice
//     );

//     setSelectedRange(foundIndex !== -1 ? foundIndex : null);
//   }, [searchParams]);

//   const handlePriceChange = (
//     minPrice: number | null,
//     maxPrice: number | null,
//     index: number
//   ) => {
//     if (selectedRange === index) {
//       // Deselect if the same range is clicked
//       setSelectedRange(null);
//       updateFilter({ minPrice: null, maxPrice: null });
//     } else {
//       // Select a new range
//       setSelectedRange(index);
//       updateFilter({ minPrice, maxPrice, pageIndex: 1 });
//     }
//   };

//   return (
//     <div className="px-6 py-4 rounded-lg shadow-sm bg-lightWhite4">
//       <div className="flex justify-between items-center mb-2 ">
//         <h2 className="text-xl font-medium">Khoảng giá</h2>
//         <button onClick={() => setIsOpen((prev) => !prev)}>
//           {isOpen ? (
//             <MdKeyboardArrowDown size={25} />
//           ) : (
//             <MdKeyboardArrowRight size={25} />
//           )}
//         </button>
//       </div>
//       {isOpen && (
//         <ul className="flex flex-col text-[17px] gap-[6px]">
//           {priceRanges.map((range, index) => (
//             <button
//               key={index}
//               className={`flex items-center gap-1 ${
//                 selectedRange === index && "text-primary"
//               }`}
//               onClick={() =>
//                 handlePriceChange(range.minPrice, range.maxPrice, index)
//               }
//             >
//               {selectedRange === index ? (
//                 <MdOutlineCheckBox size={20} className="text-blue-600" /> // Active icon and color
//               ) : (
//                 <MdOutlineCheckBoxOutlineBlank size={20} />
//               )}
//               {range.label}
//             </button>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// export default FilterPrice;

// import { useState } from "react";
// import {
//   MdKeyboardArrowDown,
//   MdKeyboardArrowRight,
//   MdOutlineCheckBox,
//   MdOutlineCheckBoxOutlineBlank,
// } from "react-icons/md";

// interface PriceRangeProps {
//   updateFilter: (newFilter: {
//     minPrice?: number | null;
//     maxPrice?: number | null;
//   }) => void;
// }

// const FilterPrice: React.FC<PriceRangeProps> = ({ updateFilter }) => {
//   const [isOpen, setIsOpen] = useState(true);
//   const [selectedRange, setSelectedRange] = useState<number | null>(null);

//   const priceRanges = [
//     { label: "Dưới 500.000 đ", minPrice: 0, maxPrice: 500000 },
//     { label: "500.000 đ - 1 triệu", minPrice: 500000, maxPrice: 1000000 },
//     { label: "1 triệu - 2 triệu", minPrice: 1000000, maxPrice: 2000000 },
//     { label: "2 triệu - 3 triệu", minPrice: 2000000, maxPrice: 3000000 },
//     { label: "Trên 3 triệu", minPrice: 3000000, maxPrice: null },
//   ];

//   const handlePriceChange = (
//     minPrice: number | null,
//     maxPrice: number | null,
//     index: number
//   ) => {
//     if (selectedRange === index) {
//       // Deselect if the same range is clicked
//       setSelectedRange(null);
//       updateFilter({ minPrice: null, maxPrice: null });
//     } else {
//       // Select a new range
//       setSelectedRange(index);
//       updateFilter({ minPrice, maxPrice });
//     }
//   };

//   return (
//     <div className="px-6 py-4 rounded-lg shadow-sm bg-lightWhite4">
//       <div className="flex justify-between items-center mb-2 ">
//         <h2 className="text-xl font-medium">Khoảng giá</h2>
//         <button onClick={() => setIsOpen((prev) => !prev)}>
//           {isOpen ? (
//             <MdKeyboardArrowDown size={25} />
//           ) : (
//             <MdKeyboardArrowRight size={25} />
//           )}
//         </button>
//       </div>
//       {isOpen && (
//         <ul className="flex flex-col text-[17px] gap-[6px]">
//           {priceRanges.map((range, index) => (
//             <button
//               key={index}
//               className={`flex items-center gap-1 ${
//                 selectedRange === index && "text-primary"
//               }`}
//               onClick={() =>
//                 handlePriceChange(range.minPrice, range.maxPrice, index)
//               }
//             >
//               {selectedRange === index ? (
//                 <MdOutlineCheckBox size={20} className="text-blue-600" /> // Active icon and color
//               ) : (
//                 <MdOutlineCheckBoxOutlineBlank size={20} />
//               )}
//               {range.label}
//             </button>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// export default FilterPrice;
