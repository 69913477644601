import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState, useRef } from "react";
import { fetchBrands } from "../../../services/home";
import { useSearchParams } from "react-router-dom";
import {
  MdKeyboardArrowDown,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from "react-icons/md";
import { Filter } from "../../../shared/types";

interface FilterBrandsProps {
  updateFilter: (newFilterValues: Partial<Filter>) => void;
}

const FilterBrands: React.FC<FilterBrandsProps> = ({ updateFilter }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false); // Start closed by default
  const dropdownRef = useRef<HTMLDivElement>(null); // Reference for outside click

  const {
    isLoading: isLoadingBrand,
    error: errorBrand,
    data: dataBrand,
  } = useQuery({
    queryKey: ["brands"],
    queryFn: fetchBrands,
  });

  // Initialize the selected brands based on query params
  useEffect(() => {
    const brandsFromParams = searchParams.get("brandCodes");
    if (brandsFromParams) {
      const brandList = brandsFromParams.split(",");
      setSelectedBrands(brandList);
    } else {
      setSelectedBrands([]);
    }
  }, [searchParams]);

  const toggleBrand = (brand: string) => {
    setSelectedBrands((prev) => {
      const updatedBrands = prev.includes(brand)
        ? prev.filter((b) => b !== brand)
        : [...prev, brand];

      // Update filters with the new selected brands
      updateFilter({ BrandCodes: updatedBrands.join(","), pageIndex: 1 });

      // Update the query params in the URL
      // const params = new URLSearchParams(searchParams);
      // if (updatedBrands.length > 0) {
      //   params.set("brandCodes", updatedBrands.join(","));
      // } else {
      //   params.delete("brandCodes");
      // }
      // setSearchParams(params);

      return updatedBrands;
    });
  };

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (isLoadingBrand) {
    return <div>Loading...</div>;
  }

  if (errorBrand) {
    return <div>Error fetching brands</div>;
  }

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Filter Header */}
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className=" flex justify-between items-center px-4 py-2 border rounded-md shadow-sm text-gray-700 bg-white focus:outline-none w-[250px]"
      >
        <span>Thương hiệu</span>
        <MdKeyboardArrowDown
          size={20}
          className={`transition-transform duration-200 ${
            isOpen ? "-rotate-90" : "rotate-0"
          }`}
        />
      </button>

      {/* Dropdown Content */}
      {isOpen && (
        <div className="absolute z-10 mt-2 w-[250px]  bg-white border rounded-md shadow-lg p-4 custom-scrollbar">
          <ul className="space-y-2 max-h-64 overflow-y-auto">
            {dataBrand?.data.map((brand, index) => (
              <li key={index} className="flex items-center">
                <button
                  onClick={() => toggleBrand(brand.brandCode)}
                  className="flex items-center gap-2 w-full text-left"
                >
                  {selectedBrands.includes(brand.brandCode) ? (
                    <MdOutlineCheckBox size={24} className="text-primary" />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank
                      size={24}
                      className="text-gray-400"
                    />
                  )}
                  <span
                    className={`${
                      selectedBrands.includes(brand.brandCode) &&
                      "text-primary font-medium"
                    }`}
                  >
                    {brand.brandName}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FilterBrands;

// import { useQuery } from "@tanstack/react-query";
// import React, { useEffect, useState } from "react";
// import { fetchBrands } from "../../../services/home";
// import { useSearchParams } from "react-router-dom";
// import {
//   MdKeyboardArrowDown,
//   MdOutlineCheckBox,
//   MdOutlineCheckBoxOutlineBlank,
// } from "react-icons/md";
// import { Filter } from "../../../shared/types";

// interface FilterBrandsProps {
//   updateFilter: (newFilterValues: Partial<Filter>) => void;
// }

// const FilterBrands: React.FC<FilterBrandsProps> = ({ updateFilter }) => {
//   const [searchParams, setSearchParams] = useSearchParams();
//   const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
//   const [isOpen, setIsOpen] = useState(false); // Start closed by default

//   const {
//     isLoading: isLoadingBrand,
//     error: errorBrand,
//     data: dataBrand,
//   } = useQuery({
//     queryKey: ["brands"],
//     queryFn: fetchBrands,
//   });

//   // Initialize the selected brands based on query params
//   useEffect(() => {
//     const brandsFromParams = searchParams.get("brandCodes");
//     if (brandsFromParams) {
//       const brandList = brandsFromParams.split(",");
//       setSelectedBrands(brandList);
//     } else {
//       setSelectedBrands([]);
//     }
//   }, [searchParams]);

//   const toggleBrand = (brand: string) => {
//     setSelectedBrands((prev) => {
//       const updatedBrands = prev.includes(brand)
//         ? prev.filter((b) => b !== brand)
//         : [...prev, brand];

//       // Update filters with the new selected brands
//       updateFilter({ BrandCodes: updatedBrands.join(","), pageIndex: 1 });

//       // Update the query params in the URL
//       const params = new URLSearchParams(searchParams);
//       if (updatedBrands.length > 0) {
//         params.set("brandCodes", updatedBrands.join(","));
//       } else {
//         params.delete("brandCodes");
//       }
//       setSearchParams(params);

//       return updatedBrands;
//     });
//   };

//   if (isLoadingBrand) {
//     return <div>Loading...</div>;
//   }

//   if (errorBrand) {
//     return <div>Error fetching brands</div>;
//   }

//   return (
//     <div className="relative">
//       {/* Filter Header */}
//       <button
//         onClick={() => setIsOpen((prev) => !prev)}
//         className="w-full flex justify-between items-center px-4 py-2 border rounded-md shadow-sm text-gray-700 bg-white focus:outline-none"
//       >
//         <span>Thương hiệu</span>
//         <MdKeyboardArrowDown
//           size={20}
//           className={`transition-transform duration-200 ${
//             isOpen ? "rotate-180" : "rotate-0"
//           }`}
//         />
//       </button>

//       {/* Dropdown Content */}
//       {isOpen && (
//         <div className="absolute z-10 mt-2 w-full bg-white border rounded-md shadow-lg p-4">
//           <ul className="space-y-2 max-h-48 overflow-y-auto">
//             {dataBrand?.data.map((brand, index) => (
//               <li key={index} className="flex items-center">
//                 <button
//                   onClick={() => toggleBrand(brand.brandCode)}
//                   className="flex items-center gap-2 w-full text-left"
//                 >
//                   {selectedBrands.includes(brand.brandCode) ? (
//                     <MdOutlineCheckBox size={20} className="text-blue-600" />
//                   ) : (
//                     <MdOutlineCheckBoxOutlineBlank size={20} />
//                   )}
//                   <span>{brand.brandName}</span>
//                 </button>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//     </div>
//   );
// };

// export default FilterBrands;

// import { useQuery } from "@tanstack/react-query";
// import React, { useEffect, useState } from "react";
// import { fetchBrands } from "../../../services/home";
// import { useSearchParams } from "react-router-dom";
// import {
//   MdKeyboardArrowDown,
//   MdKeyboardArrowRight,
//   MdOutlineCheckBox,
//   MdOutlineCheckBoxOutlineBlank,
// } from "react-icons/md";
// import { Filter } from "../../../shared/types";

// interface FilterBrandsProps {
//   updateFilter: (newFilterValues: Partial<Filter>) => void;
// }

// const FilterBrands: React.FC<FilterBrandsProps> = ({ updateFilter }) => {
//   const [searchParams] = useSearchParams();
//   const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
//   const [isOpen, setIsOpen] = useState(true);

//   const {
//     isLoading: isLoadingBrand,
//     error: errorBrand,
//     data: dataBrand,
//   } = useQuery({
//     queryKey: ["brands"],
//     queryFn: fetchBrands,
//   });

//   // Initialize the selected brands based on query params
//   useEffect(() => {
//     const brandsFromParams = searchParams.get("brandCodes");
//     if (brandsFromParams) {
//       const brandList = brandsFromParams.split(",");
//       setSelectedBrands(brandList);
//     } else {
//       setSelectedBrands([]);
//     }
//   }, [searchParams]);

//   const toggleBrand = (brand: string) => {
//     setSelectedBrands((prev) => {
//       const updatedBrands = prev.includes(brand)
//         ? prev.filter((b) => b !== brand)
//         : [...prev, brand];

//       // Update filters with the new selected brands
//       updateFilter({ BrandCodes: updatedBrands.join(","), pageIndex: 1 });
//       return updatedBrands;
//     });
//   };

//   if (isLoadingBrand) {
//     return <div>Loading...</div>;
//   }

//   if (errorBrand) {
//     return <div>Error fetching brands</div>;
//   }

//   return (
//     <div className="p-4 bg-gray-100 rounded-md shadow-md">
//       <div className="flex justify-between items-center mb-2">
//         <h2 className="text-xl font-medium">Thương hiệu</h2>
//         <button onClick={() => setIsOpen((prev) => !prev)}>
//           {isOpen ? (
//             <MdKeyboardArrowDown size={25} />
//           ) : (
//             <MdKeyboardArrowRight size={25} />
//           )}
//         </button>
//       </div>
//       {isOpen && (
//         <ul className="flex flex-col space-y-4">
//           {dataBrand?.data.map((brand, index) => (
//             <button
//               key={index}
//               className={`flex items-center gap-1 ${
//                 selectedBrands.includes(brand.brandCode) ? "text-primary" : ""
//               }`}
//               onClick={() => toggleBrand(brand.brandCode)}
//             >
//               {selectedBrands.includes(brand.brandCode) ? (
//                 <MdOutlineCheckBox size={20} className="text-blue-600" />
//               ) : (
//                 <MdOutlineCheckBoxOutlineBlank size={20} />
//               )}
//               {brand.brandName}
//             </button>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// export default FilterBrands;

// import { useQuery } from "@tanstack/react-query";
// import React, { useEffect, useState } from "react";
// import { fetchBrands } from "../../../services/home";
// import { useSearchParams } from "react-router-dom";
// import {
//   MdKeyboardArrowDown,
//   MdKeyboardArrowRight,
//   MdOutlineCheckBox,
//   MdOutlineCheckBoxOutlineBlank,
// } from "react-icons/md";

// interface FilterBrandsProps {
//   updateFilter: (newFilter: { BrandCodes?: string }) => void;
// }

// const FilterBrands: React.FC<FilterBrandsProps> = ({ updateFilter }) => {
//   const [searchParams] = useSearchParams();
//   const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
//   const [isOpen, setIsOpen] = useState(true);

//   const {
//     isLoading: isLoadingBrand,
//     error: errorBrand,
//     data: dataBrand,
//   } = useQuery({
//     queryKey: ["brands"],
//     queryFn: fetchBrands,
//   });

//   // Initialize the selected brands based on query params
//   useEffect(() => {
//     const brandsFromParams = searchParams.get("brandCodes");
//     if (brandsFromParams) {
//       const brandList = brandsFromParams.split(",");
//       setSelectedBrands(brandList);
//     } else {
//       setSelectedBrands([]);
//     }
//   }, [searchParams]);

//   const toggleBrand = (brand: string) => {
//     setSelectedBrands((prev) => {
//       const updatedBrands = prev.includes(brand)
//         ? prev.filter((b) => b !== brand)
//         : [...prev, brand];

//       // Update filters with the new selected brands
//       updateFilter({ BrandCodes: updatedBrands.join(",") });
//       return updatedBrands;
//     });
//   };

//   if (isLoadingBrand) {
//     return <div>Loading...</div>;
//   }

//   if (errorBrand) {
//     return <div>Error fetching brands</div>;
//   }

//   return (
//     <div className="p-4 bg-gray-100 rounded-md shadow-md">
//       <div className="flex justify-between items-center mb-2">
//         <h2 className="text-xl font-medium">Thương hiệu</h2>
//         <button onClick={() => setIsOpen((prev) => !prev)}>
//           {isOpen ? (
//             <MdKeyboardArrowDown size={25} />
//           ) : (
//             <MdKeyboardArrowRight size={25} />
//           )}
//         </button>
//       </div>
//       {isOpen && (
//         <ul className="flex flex-col space-y-4">
//           {dataBrand?.data.map((brand, index) => (
//             <button
//               key={index}
//               className={`flex items-center gap-1 ${
//                 selectedBrands.includes(brand.brandName) ? "text-primary" : ""
//               }`}
//               onClick={() => toggleBrand(brand.brandName)}
//             >
//               {selectedBrands.includes(brand.brandName) ? (
//                 <MdOutlineCheckBox size={20} className="text-blue-600" />
//               ) : (
//                 <MdOutlineCheckBoxOutlineBlank size={20} />
//               )}
//               {brand.brandName}
//             </button>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// export default FilterBrands;

// import { useQuery } from "@tanstack/react-query";
// import React, { useEffect, useState } from "react";
// import { fetchBrands } from "../../../services/home";
// import { useSearchParams } from "react-router-dom";
// import {
//   MdKeyboardArrowDown,
//   MdKeyboardArrowRight,
//   MdOutlineCheckBox,
//   MdOutlineCheckBoxOutlineBlank,
// } from "react-icons/md";

// interface FilterBrandsProps {
//   updateFilter: (newFilter: { BrandCodes?: string }) => void;
// }

// const FilterBrands: React.FC<FilterBrandsProps> = ({ updateFilter }) => {
//   const [searchParams] = useSearchParams();
//   const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
//   const [isOpen, setIsOpen] = useState(true);
//   const {
//     isPending: isLoadingBrand,
//     error: errorBrand,
//     data: dataBrand,
//   } = useQuery({
//     queryKey: ["brands"],
//     queryFn: fetchBrands,
//   });

//   // Initialize the selected brands based on query params
//   useEffect(() => {
//     const brandsFromParams = searchParams.get("brandCodes");
//     console.warn(brandsFromParams);
//     if (brandsFromParams) {
//       const brandList = brandsFromParams.split(",");
//       setSelectedBrands(brandList);
//     } else {
//       setSelectedBrands([]);
//     }
//   }, [searchParams]);

//   console.log(selectedBrands);

//   if (isLoadingBrand) {
//     return <div>Loading...</div>;
//   }

//   if (errorBrand) {
//     return <div>Error fetching brands</div>;
//   }

//   const toggleBrand = (brand: string) => {
//     setSelectedBrands((prev) => {
//       const updatedBrands = prev.includes(brand)
//         ? prev.filter((b) => b !== brand)
//         : [...prev, brand];
//       updateFilter({ BrandCodes: updatedBrands.join(",") });
//       return updatedBrands;
//     });
//   };

//   return (
//     <div className="p-4 bg-gray-100 rounded-md shadow-md">
//       <div className="flex justify-between items-center mb-2 ">
//         <h2 className="text-xl font-medium">Thương hiệu</h2>
//         <button onClick={() => setIsOpen((prev) => !prev)}>
//           {isOpen ? (
//             <MdKeyboardArrowDown size={25} />
//           ) : (
//             <MdKeyboardArrowRight size={25} />
//           )}
//         </button>
//       </div>
//       {isOpen && (
//         <ul className="flex flex-col space-y-4">
//           {dataBrand.data.map((brand, index) => (
//             <label key={index} className="flex items-center space-x-2">
//               {/* <input
//               type="checkbox"
//               checked={selectedBrands.includes(brand.brandName)}
//               onChange={() => toggleBrand(brand.brandName)}
//             />
//             <span>{brand.brandName}</span> */}
//               <button
//                 key={index}
//                 className={`flex items-center gap-1 ${
//                   selectedBrands.includes(brand.brandName) ? "text-primary" : ""
//                 }`}
//                 onClick={() => toggleBrand(brand.brandName)}
//               >
//                 {selectedBrands.includes(brand.brandName) ? (
//                   <MdOutlineCheckBox size={20} className="text-blue-600" />
//                 ) : (
//                   <MdOutlineCheckBoxOutlineBlank size={20} />
//                 )}
//                 {brand.brandName}
//               </button>
//             </label>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// export default FilterBrands;
