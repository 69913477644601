export const CATEGORIES = [
  {
    CategoryCode: 102,
    CategoryName: "Dịch vụ",
    CategorySlug: "dich-vu",
    filters: [], // Không có filter
  },
  {
    CategoryCode: 50,
    CategoryName: "Vợt cầu lông",
    CategorySlug: "vot-cau-long",
    CategoryImg:
      "https://product.hstatic.net/200000099191/product/d78298bf19e8572943f612675f6237561111_00f5300a2fc54b0ebb6b051b44303666.jpg",
    filters: ["COLOR1", "SIZE2"],
  },
  {
    CategoryCode: 57,
    CategoryName: "Quần Cầu Lông",
    CategorySlug: "quan-cau-long",
    CategoryImg:
      "https://product.hstatic.net/200000099191/product/324_7c4b8e7e358546abab75643f3b8ea9f7.jpg",
    filters: ["SIZE1"],
  },
  {
    CategoryCode: 56,
    CategoryName: "Váy Cầu Lông",
    CategorySlug: "vay-cau-long",
    CategoryImg:
      "https://aocaulong.com/images/pro/vay-cau-long-dang-chu-a-3901_13229.jpg",
    filters: ["SIZE1"],
  },
  {
    CategoryCode: 58,
    CategoryName: "Balo Cầu Lông",
    CategorySlug: "balo-cau-long",
    CategoryImg:
      "https://tuanvisport.com.vn/wp-content/uploads/2024/06/balo-cau-long-yonex-1408-gia-cong-6.jpg",
    filters: [],
  },
  {
    CategoryCode: 51,
    CategoryName: "Túi Cầu Lông",
    CategorySlug: "tui-cau-long",
    CategoryImg:
      "https://cdn.shopvnb.com/uploads/gallery/tui-cau-long-yonex-239bt006u-trang-den-gia-cong-2.webp",
    filters: [],
  },
  {
    CategoryCode: 59,
    CategoryName: "Phụ Kiện Cầu Lông",
    CategorySlug: "phu-kien-cau-long",
    CategoryImg:
      "https://product.hstatic.net/200000099191/product/thiet_ke_chua_co_ten__6__b34ca96b08b84166bdcfa427b3770943_grande.jpg",
    filters: [],
  },
  {
    CategoryCode: 52,
    CategoryName: "Giày Cầu Lông",
    CategorySlug: "giay-cau-long",
    CategoryImg:
      "https://product.hstatic.net/1000341630/product/kmbs220124_c1d152f054d94f4a8617f6aa9c35aff8_master.jpg",
    filters: ["COLOR3", "SIZE3"],
  },
  {
    CategoryCode: 55,
    CategoryName: "Áo Cầu Lông",
    CategorySlug: "ao-cau-long",
    CategoryImg:
      "https://product.hstatic.net/200000099191/product/tai_xuong_1350d6a03ce2464fbb11c7d719dc0207.jpg",
    filters: ["SIZE1"],
  },
];

export const SAMPLE_DESCRIPTION = `Sản phẩm này được thiết kế tinh tế với chất liệu cao cấp, mang lại sự thoải mái và hiệu suất vượt trội. Với kiểu dáng hiện đại cùng các tính năng tiên tiến, sản phẩm phù hợp cho cả người mới bắt đầu lẫn người dùng chuyên nghiệp. Đây là lựa chọn lý tưởng để nâng cao trải nghiệm của bạn trong mọi hoạt động.`;

export const SALE_BADGE_CONDITION = 10;

export const BRAND_MAPPING: { [key: string]: string } = {
  FL: "Felet",
  FZ: "Forza",
  PK: "PROKEN NEX",
  KZ: "Kizuna",
  APV: "Apavi",
  VS: "VS",
  LN: "Lining",
  KW: "Kawasaki",
  YN: "Yonex",
  MZ: "Mizuno",
  TT: "T&T",
  VT: "Victor",
  AP: "Apacs",
  KM: "Kamito",
  PR: "Proace",
  KP: "Kumpoo",
  LF: "Lefus",
  VL: "Vicleo",
};

export const FILTER_MAPPING = {
  GENDER: "Giới tính",
  COLOR3: "Màu sắc (Giày)",
  COLOR1: "Màu sắc (Vợt)",
  SIZE1: "Size quần áo",
  SIZE2: "Size vợt",
  SIZE3: "Size giày",
};

export const ACCESSORY_CATEGORY_MAPPING: { [key: string]: string } = {
  "110": "Băng Chặn Mồ Hôi",
  "114": "Phụ Kiện",
  "113": "Bọc Cán Vợt",
  "109": "Băng Bó Cơ",
  "108": "Quả Cầu Lông",
  "104": "Dây Cước Căng Vợt",
  "115": "Túi Nhung Đựng Vợt",
  "112": "Bình Xịt",
  "116": "Gen Vợt",
  "107": "Vớ Cầu Lông",
  "111": "Phấn Hút Mồ Hôi",
  "106": "Bao Vợt Cầu Lông",
  "105": "Quấn cán",
};
