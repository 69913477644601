import React, { useEffect, useState, useRef } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FILTER_MAPPING } from "../../../shared/constant";
import { useSearchParams } from "react-router-dom";

interface FilterBatchCharacteristicProps {
  type: string; // e.g., "SIZE3", "COLOR3"
  options: {
    batchCharacteristicValueId: string;
    batchCharacteristicValueCode: string;
    batchCharacteristicValueName: string;
  }[]; // Options from the API
  updateFilter: (newFilterValues: { [key: string]: string | null }) => void; // Update function for the filter
}

const FilterBatchCharacteristic: React.FC<FilterBatchCharacteristicProps> = ({
  type,
  options,
  updateFilter,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Initialize the selected price range based on query params
  useEffect(() => {
    const batchValue = searchParams.get(`batch${type}`) || null;

    setSelectedValue(batchValue);
  }, [searchParams, type]);

  // Handle selecting a filter option
  const handleOptionChange = (value: string) => {
    if (selectedValue === value) {
      // Deselect the current option
      setSelectedValue(null);
      updateFilter({ [`batch${type}`]: null });
    } else {
      // Select a new option
      setSelectedValue(value);
      updateFilter({ [`batch${type}`]: value });
    }
  };

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Filter Header */}
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className="flex justify-between items-center px-4 py-2 border rounded-md shadow-sm text-gray-700 bg-white focus:outline-none w-[250px]"
      >
        {/* @ts-ignore */}
        <span>{FILTER_MAPPING[type] || "Lọc"}</span>
        <MdKeyboardArrowDown
          size={20}
          className={`transition-transform duration-200 ${
            isOpen ? "-rotate-90" : "rotate-0"
          }`}
        />
      </button>

      {/* Dropdown Content */}
      {isOpen && (
        <div className="absolute z-10 mt-2 bg-white border rounded-md shadow-lg p-4 w-[250px] custom-scrollbar">
          <ul className="space-y-2 max-h-64 overflow-y-auto">
            {options.map((option) => (
              <li
                key={option.batchCharacteristicValueId}
                className="flex items-center"
              >
                <button
                  onClick={() =>
                    handleOptionChange(option.batchCharacteristicValueCode)
                  }
                  className="flex items-center gap-2 w-full text-left"
                >
                  <span
                    className={`w-5 h-5 flex items-center justify-center rounded-full border-2 ${
                      selectedValue === option.batchCharacteristicValueCode
                        ? "bg-white border-primary"
                        : "border-gray-400"
                    }`}
                  >
                    {selectedValue === option.batchCharacteristicValueCode && (
                      <span className="w-3 h-3 bg-primary rounded-full"></span>
                    )}
                  </span>
                  <span
                    className={`${
                      selectedValue === option.batchCharacteristicValueCode &&
                      "text-primary font-medium"
                    }`}
                  >
                    {option.batchCharacteristicValueName}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FilterBatchCharacteristic;
