import React, { useEffect, useState, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import {
  MdKeyboardArrowDown,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from "react-icons/md";
import { fetchCategories } from "../../../services/home";
import { Filter } from "../../../shared/types";

interface FilterAccessoryProps {
  updateFilter: (newFilterValues: Partial<Filter>) => void;
}

const FilterAccessory: React.FC<FilterAccessoryProps> = ({ updateFilter }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedAccessory, setSelectedAccessory] = useState<string | null>(
    null
  );
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { error: errorCategory, data: dataCategory } = useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
  });

  const accessoryTypeOptions = dataCategory?.data.find(
    (category) => category.categoryName === "Phụ Kiện Cầu Lông"
  )?.childCategories;

  useEffect(() => {
    const accessoryFromParams = searchParams.get("accessoryDetailCode");
    console.warn(accessoryFromParams);
    if (accessoryFromParams) {
      setSelectedAccessory(accessoryFromParams);
    } else {
      setSelectedAccessory(null);
    }
  }, [searchParams]);
  const selectAccessory = (accessoryCode: string) => {
    setSelectedAccessory((prev) => {
      const newAccessory = prev === accessoryCode ? null : accessoryCode;

      updateFilter({
        accessoryDetailCode: newAccessory,
        pageIndex: 1,
      });

      // const params = new URLSearchParams(searchParams);
      // if (newAccessory) {
      //   params.set("accessoryDetailCode", newAccessory);
      // } else {
      //   params.delete("accessoryDetailCode");
      // }
      // setSearchParams(params);

      return newAccessory;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!accessoryTypeOptions || accessoryTypeOptions.length === 0) {
    return <div>Loading...</div>;
  }

  if (errorCategory) {
    return <div>Error fetching accessory types</div>;
  }

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Filter Header */}
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className="flex justify-between items-center px-4 py-2 border rounded-md shadow-sm text-gray-700 bg-white focus:outline-none w-[250px]"
      >
        <span>Loại phụ kiện</span>
        <MdKeyboardArrowDown
          size={20}
          className={`transition-transform duration-200 ${
            isOpen ? "-rotate-90" : "rotate-0"
          }`}
        />
      </button>

      {/* Dropdown Content */}
      {isOpen && (
        <div className="absolute z-10 mt-2 w-[250px] bg-white border rounded-md shadow-lg p-4 custom-scrollbar">
          <ul className="space-y-2 max-h-64 overflow-y-auto">
            {accessoryTypeOptions.map((option) => (
              <li key={option.categoryCode} className="flex items-center">
                <button
                  onClick={() => selectAccessory(option.categoryCode)}
                  className="flex items-center gap-2 w-full text-left"
                >
                  <span
                    className={`w-5 h-5 flex items-center justify-center rounded-full border-2 ${
                      selectedAccessory === option.categoryCode
                        ? "bg-white border-primary"
                        : "border-gray-400"
                    }`}
                  >
                    {selectedAccessory === option.categoryCode && (
                      <span className="w-3 h-3 bg-primary rounded-full"></span>
                    )}
                  </span>

                  <span
                    className={`${
                      selectedAccessory === option.categoryCode &&
                      "text-primary font-medium"
                    }`}
                  >
                    {option.categoryName}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FilterAccessory;
