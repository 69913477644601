import axiosInstance from "../shared/axiosInstance";
import { APIResponse, BatchCharacteristic } from "../shared/types";

export const fetchAllBatchCharacteristic = async (): Promise<
  APIResponse<BatchCharacteristic>
> => {
  try {
    const response = await axiosInstance.get<APIResponse<BatchCharacteristic>>(
      "/api/v1/categories/get-all-batch-characteristic"
    );

    if (response.status === 200 && response.data.isSuccess) {
      return response.data;
    } else {
      throw new Error(response.data.message || "Failed to fetch main products");
    }
  } catch (error) {
    console.error("Error fetching products:", error);
    throw new Error("An error occurred while fetching products");
  }
};
