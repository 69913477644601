// ChatWidget.js
import { useEffect } from "react";

const ChatWidget = () => {
  useEffect(() => {
    if (
      window.location.origin === "http://localhost:3000" ||
      window.location.origin === "http://localhost:3001"
    )
      return;
    // Load the Zalo SDK script
    const script = document.createElement("script");
    script.src = "https://sp.zalo.me/plugins/sdk.js";
    script.async = true;
    document.body.appendChild(script);

    // Clean up script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="zalo-chat-widget"
      data-oaid="794468836944095128"
      data-welcome-message="Rất vui khi được hỗ trợ bạn, Bạn vui lòng cho mình sđt để tiện tư vấn ạ!"
      data-autopopup="0"
      data-width=""
      data-height=""
    ></div>
  );
};

export default ChatWidget;
